<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import NfeUpload from "@/components/widgets/sales-nfe-upload";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
    NfeUpload,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Produtos", value: "R$ -,--", icon: "bx-package", },
        { type: "line", title: "Frete", value: "R$ -,--", icon: "bxs-truck", },
        { type: "line", title: "Entrega", value: "-", icon: "bx-time", },
        { type: "line", title: "Total", value: "R$ -,--", icon: "bx-dollar-circle", },
      ],

      loading: false,
      errored: false,

      order: null,

      fields: {
        products: [
          { key: "sku", label: "SKU" },
          { key: "image", label: "" },
          { key: "name", label: "Nome" },
          { key: "price", label: "Preço" },
          { key: "qty", label: "Qtd" },
          { key: "subtotal", label: "Subtotal" },
        ],
        nfe: [
          { key: "id", label: "#" },
          { key: "name", label: "Arquivo" },
          { key: "url", label: "" },
        ]
      },

      products: null,

      status: {
        modal: false,
        loading: false,
        notification: true,
        id: "",
      },

      tracking: {
        modal: false,
        loading: false,
        notification: true,
        company: "",
        other: "",
        code: "",
        status: "",
      },

      nfe: {
        add: {
          modal: false,
          loading: false,
          notification: true,
        },
        del: {
          modal: false,
          loading: false,
        },
      },
    };
  },
  methods: {
    getOrder() {
      this.loading = true;

      api
        .get("sales/orders/" + this.$route.params.id)
        .then((res) => {
          if (res.data.status == "success") {
            this.order = res.data.order;

            this.tracking.company = this.order.tracking.company.slug;
            this.tracking.other = this.order.tracking.company.name;
            this.tracking.code = this.order.tracking.code;

            this.nfes = res.data.order.nfes;

            this.status.id = this.order.status.current;

            this.statData = [
              { type: "line", title: "Produtos", value: this.$options.filters.currency(this.order.products.total).toString() + " <sup class='badge badge-total'>" + this.order.products.qty  + "</sup>", icon: "bx-package", },
              { type: "line", title: "Frete", value: this.$options.filters.currency(this.order.shipping.value).toString(), icon: "bxs-truck", },
              { type: "line", title: "Entrega", value: this.order.shipping.time + " dias", icon: "bx-time", },
              { type: "line", title: "Total", value: this.$options.filters.currency(this.order.total).toString(), icon: "bx-dollar-circle", },
            ];
          } else {
            this.$router.push('/sales/orders');
          }

          this.loading = false;
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/sales/orders");
          }
        });
    },
    closeAll() {
      this.status.modal = false;
      this.status.loading = false;

      this.tracking.modal = false;
      this.tracking.loading = false;

      this.nfe.add.modal = false;
      this.nfe.add.loading = false;

      this.nfe.del.modal = false;
      this.nfe.del.loading = false;
    },
    showStatus() {
      this.closeAll();
      this.status.modal = false;
      this.status.modal = true;
    },
    saveStatus() {
      this.status.loading = true;

      api
        .post("sales/orders/status", {
          id: this.$route.params.id,
          status: this.status.id,
          notification: this.status.notification,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.status.modal = false;
            this.status.notification = true;

            this.$toast.success(response.data.message);
            this.getOrder();
          } else {
            this.$toast.error(response.data.message);
          }

          this.status.loading = false;
        })
        .catch(error => {
          if (error) {
            this.status.loading = false;
            this.$toast.error('Ocorreu um erro ao salvar o status do pedido, tente novamente.');
          }
        })
        .finally(() => {
          this.status.loading = false;
        });
    },
    showTracking() {
      this.closeAll();
      this.tracking.modal = false;
      this.tracking.modal = true;
    },
    saveTracking() {
      this.tracking.loading = true;

      api
        .post("sales/orders/tracking", {
          id: this.$route.params.id,
          company: this.tracking.company,
          other: this.tracking.other,
          code: this.tracking.code,
          notification: this.tracking.notification,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.tracking.modal = false;
            this.tracking.notification = true;

            this.$toast.success(response.data.message);
            this.getOrder();
          } else {
            this.$toast.error(response.data.message);
          }

          this.tracking.loading = false;
        })
        .catch(error => {
          if (error) {
            this.tracking.loading = false;
            this.$toast.error('Ocorreu um erro ao salvar o código de rastreio, tente novamente.');
          }
        })
        .finally(() => {
          this.tracking.loading = false;
        });
    },
    showAddNfe() {
      this.closeAll();
      this.nfe.add.modal = false;
      this.nfe.add.modal = true;
    },
    addNfe() {
    },
    showDelNfe(item) {
      this.closeAll();
      this.nfe.del.modal = true;
      this.nfe.del.id = item.id;
    },
    delNfe(id) {
      if (id) {
        this.nfe.del.loading = true;

        api
          .delete("sales/orders/" + this.$route.params.id + "/nfe/" + id)
          .then((res) => {
            if (res.data.status == "success") {
              this.nfe.del.modal = false;
              this.$toast.success(res.data.message);

              this.getOrder();
            } else {
              this.$toast.error(res.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.nfe.del.modal = false;
              this.nfe.del.loading = false;
            }
          })
          .finally(() => {
            this.nfe.del.loading = false;
          });
      }
    },
    clickNfe(item) {
      if (item.url) {
        window.open(item.url, '_blank');
      }
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Pedidos</li>
      <li class="breadcrumb-item"><router-link tag="a" to="/sales/orders">Lista de Pedidos</router-link></li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Ver pedido</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!order" class="card">
      <div class="card-body text-center">
        <b-spinner variant="default"></b-spinner>
      </div>
    </div>
    <template v-else-if="order && order.id">
      <div id="dropdown" class="d-flex mb-4" v-if="order.status && order.status.current && order.status.change">
        <div class="totals">
          <h6 class="m-0">{{ order.id }}</h6>
          <h6 class="m-0 text-secondary">No. do pedido</h6>
        </div>
        <div class="totals">
          <h6 class="m-0" v-if="order.status.current == 'pending'">Pendente</h6>
          <h6 class="m-0" v-else-if="order.status.current == 'approved'">Aprovado</h6>
          <h6 class="m-0" v-else-if="order.status.current == 'in_process'">Em separação</h6>
          <h6 class="m-0" v-else-if="order.status.current == 'shipped'">Enviado</h6>
          <h6 class="m-0" v-else-if="order.status.current == 'delivered'">Entregue</h6>
          <h6 class="m-0" v-else-if="order.status.current == 'canceled'">Cancelado</h6>
          <h6 class="m-0 text-secondary">Status do pedido</h6>
        </div>
        <b-dropdown variant="outline-light">
          <template #button-content>
            Ações
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
            </svg>
          </template>
          <b-dropdown-item v-on:click="showTracking()" :disabled="order.status.change == 'no'">Alterar código de rastreio</b-dropdown-item>
          <b-dropdown-item v-on:click="showAddNfe()" :disabled="order.status.change == 'no'">Alterar nota fiscal</b-dropdown-item>
          <b-dropdown-item v-on:click="showStatus()" :disabled="order.status.change == 'no'">Alterar status do pedido</b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
          <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
        </div>
      </div>

      <div class="order-info row">
        <div class="col-md-6">
          <fieldset class="card-h-fix">
            <legend>Informações do cliente</legend>
            <div class="client-info">
              <h6 class="m-0">{{ order.client.name }}</h6>
              <h6 class="m-0">{{ order.client.person_number }}</h6>
              <h6 class="m-0">{{ order.client.email }}</h6>
              <h6 class="m-0">{{ order.client.cellphone }}</h6>
              <a class="btn btn-outline-success py-1 px-2 mt-2" target="_blank" :href="order.client.whatsapp">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
                <span class="ml-1">Enviar WhatsApp</span>
              </a>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <fieldset class="card-h-fix">
            <legend>Informações do envio</legend>
            <div class="shipping-info">
              <h6 class="m-0">{{ order.shipping.details.address }}, {{ order.shipping.details.number }}</h6>
              <h6 class="m-0" v-if="order.shipping.details.complement">{{ order.shipping.details.complement }}</h6>
              <h6 class="m-0">{{ order.shipping.details.district }}</h6>
              <h6 class="m-0">{{ order.shipping.details.city }} - {{ order.shipping.details.state }}</h6>
              <h6 class="m-0">{{ order.shipping.details.zipcode }}</h6>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row" v-if="order.status && order.status.change && order.status.change == 'yes'">
        <div class="col-md-6">
          <fieldset>
            <legend>Nota Fiscal</legend>
            <div v-if="order.nfe.status == 'pending'" class="text-danger">
              * a nota fiscal ainda não foi cadastrada
            </div>
            <div v-else>
              <a class="text-dark" target="_blank" :href="order.nfe.url">
                <div class="d-flex align-items-center">
                  <div class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                      <path
                        d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z">
                      </path>
                      <path
                        d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z">
                      </path>
                    </svg>
                  </div>
                  <div class="h6 m-0 pt-1">
                    Abrir nota fiscal
                  </div>
                </div>
              </a>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <fieldset>
            <legend>Código de Rastreio</legend>
            <div v-if="order.tracking.status == 'pending'" class="text-danger">
              * o código de rastreio ainda não foi cadastrado
            </div>
            <div v-else>
              <div class="text-uppercase d-flex align-items-center">
                <div class="mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                    <path
                      d="M19.15 8a2 2 0 0 0-1.72-1H15V5a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 1 1.73 3.49 3.49 0 0 0 7 .27h3.1a3.48 3.48 0 0 0 6.9 0 2 2 0 0 0 2-2v-3a1.07 1.07 0 0 0-.14-.52zM15 9h2.43l1.8 3H15zM6.5 19A1.5 1.5 0 1 1 8 17.5 1.5 1.5 0 0 1 6.5 19zm10 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z">
                    </path>
                  </svg>
                </div>
                <div class="h6 m-0 pt-1">
                  {{ order.tracking.company.name }} – {{ order.tracking.code }}
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <fieldset>
        <legend>Produtos</legend>
        <div class="table-responsive">
          <b-table :items="order.products.items" :fields="fields.products" class="table-nowrap table-style table-hover" head-variant="light" responsive>
            <template #cell(image)="row">
              <img class="avatar-lg rounded" :src="row.item.image" />
            </template>
            <template #cell(name)="row">
              {{ row.item.name }}
            </template>
            <template #cell(price)="row">
              {{ row.item.price | currency }}
            </template>
            <template #cell(subtotal)="row">
              {{ row.item.subtotal | currency }}
            </template>
            <template #cell(id)="row">
              <b-dropdown variant="more">
                <template #button-content>
                  <i class="fas fa-ellipsis-h"></i>
                </template>
                <b-dropdown-item class="d-none" v-on:click="showEditItem(row.item)" :disabled="order.status && order.status.current && order.status.current !== 'open'">Editar</b-dropdown-item>
                <b-dropdown-item v-on:click="showDelItem(row.item)" class="text-danger" :disabled="order.status && order.status.current && order.status.current !== 'open'">Excluir</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </fieldset>

      <fieldset v-if="order.status && order.status.list">
        <legend>Status</legend>
        <div v-for="(row, index) in order.status.list" :key="index" class="py-1">
          {{ row.date }} - {{ row.description }}
        </div>
      </fieldset>
    </template>

    <b-modal v-if="order && order.id" v-model="status.modal" modal-class="modal-right" hide-header hide-footer>
      <div class="d-flex justify-content-between mb-5">
        <h4 class="m-0">Status do pedido</h4>
        <a class="btn btn-outline-link p-0" v-on:click="status.modal = false">Fechar</a>
      </div>
      <b-form @submit.prevent="saveStatus">
        <b-form-group label="Escolha um status" label-for="status.id">
          <select v-if="order.status && order.status.current" v-model="status.id" class="custom-select mb-2">
            <option value="approved" disabled>Aprovado</option>
            <option value="in_process" :disabled="order.status.current == 'in_process'">Em separação</option>
            <option value="shipped" :disabled="order.status.current == 'shipped'">Enviado</option>
            <option value="delivered" :disabled="order.status.current == 'delivered'">Entregue</option>
          </select>
        </b-form-group>
        <b-form-checkbox v-model="status.notification" switch size="lg"> Enviar notificação para o cliente</b-form-checkbox>
        <div class="mt-5">
          <button class="btn btn-default btn-lg btn-block text-uppercase" :disabled="this.status.loading">
            Salvar
            <b-spinner v-if="status.loading" small class="ml-2 align-middle" variant="white"></b-spinner>
          </button>
        </div>
      </b-form>
    </b-modal>

    <b-modal v-if="order && order.id" v-model="tracking.modal" modal-class="modal-right" hide-header hide-footer>
      <div class="d-flex justify-content-between mb-5">
        <h4 class="m-0">Código de Rastreio</h4>
        <a class="btn btn-outline-link p-0" v-on:click="tracking.modal = false">Fechar</a>
      </div>
      <b-form @submit.prevent="saveTracking">
        <b-form-group label="Empresa" label-for="tracking.company">
          <select v-model="tracking.company" class="custom-select">
            <option value="">– Selecione</option>
            <option value="correios">Correios</option>
            <option value="other">Outra</option>
          </select>
        </b-form-group>
        <b-form-group v-if="tracking.company == 'other'" label="Nome da Empresa" label-for="tracking.other">
          <b-form-input v-model="tracking.other" type="text"></b-form-input>
        </b-form-group>
        <b-form-group label="Código de Rastreio" label-for="tracking.code">
          <b-form-input v-model="tracking.code" type="text"></b-form-input>
        </b-form-group>
        <b-form-checkbox v-model="tracking.notification" switch size="lg"> Enviar notificação para o cliente</b-form-checkbox>
        <div class="mt-5">
          <button class="btn btn-default btn-lg btn-block text-uppercase" :disabled="this.tracking.loading">
            Salvar
            <b-spinner v-if="tracking.loading" small class="ml-2 align-middle" variant="white"></b-spinner>
          </button>
        </div>
      </b-form>
    </b-modal>

    <b-modal v-if="order && order.id" v-model="nfe.add.modal" modal-class="modal-right" hide-header hide-footer>
      <div class="d-flex justify-content-between mb-5">
        <h4 class="m-0">Nota Fiscal</h4>
        <a class="btn btn-outline-link" v-on:click="nfe.add.modal = false;">Fechar <sup>ESC</sup></a>
      </div>
      <div>
        <NfeUpload :order="order.id"></NfeUpload>
      </div>

      <div class="d-flex justify-content-between mt-3">
      </div>
    </b-modal>

    <b-modal v-if="order && order.id" v-model="nfe.del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <h4>Excluir nota fiscal</h4>
      <p>Deseja realmente excluir a nota fiscal?</p>
      <div class="d-flex">
        <button class="btn btn-default mr-3" v-on:click="delNfe(nfe.del.id)">Confirmar</button>
        <button class="btn btn-outline-link" v-on:click="nfe.del.modal = false">Fechar <sup>ESC</sup></button>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.order-info .client-info h6,
.order-info .shipping-info h6 {
  line-height: 20px;
}
</style>