<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    name: { required },
    store_id: {},
    store_order: {},
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Produtos", value: "R$ -,--", icon: "bx-package", },
        { type: "line", title: "Frete", value: "R$ -,--", icon: "bxs-truck", },
        { type: "line", title: "Entrega", value: "-", icon: "bx-time", },
        { type: "line", title: "Total", value: "R$ -,--", icon: "bx-dollar-circle", },
      ],

      loading: false,
      errored: false,

      order: null,

      fields: {
        products: [
          { key: "sku", label: "SKU" },
          { key: "image", label: "" },
          { key: "name", label: "Nome" },
          { key: "price", label: "Preço" },
          { key: "qty", label: "Qtd" },
          { key: "subtotal", label: "Subtotal" },
        ],
        nfe: [
          { key: "id", label: "#" },
          { key: "name", label: "Arquivo" },
          { key: "url", label: "" },
        ]
      },

      products: null,

      status: {
        modal: false,
        loading: false,

        id: "",
        notification: true,
      },

      nfe: {
        add: {
          modal: false,
        },
        del: {
          modal: false,
          id: null,
        },
      },
    };
  },
  methods: {
    getOrder() {
      this.loading = true;

      api
        .get("sales/orders/" + this.$route.params.id)
        .then((res) => {
          if (res.data.status == "success") {
            this.order = res.data.order;
            this.nfes = res.data.order.nfes;

            this.status.id = this.order.status;

            this.statData = [
              { type: "line", title: "Produtos", value: this.$options.filters.currency(this.order.products.total).toString() + " <sup class='badge badge-total'>" + this.order.products.qty  + "</sup>", icon: "bx-package", },
              { type: "line", title: "Frete", value: this.$options.filters.currency(this.order.shipping.value).toString(), icon: "bxs-truck", },
              { type: "line", title: "Entrega", value: this.order.shipping.time + " dias", icon: "bx-time", },
              { type: "line", title: "Total", value: this.$options.filters.currency(this.order.total).toString(), icon: "bx-dollar-circle", },
            ];
          } else {
            this.$router.push('/sales/orders');
          }

          this.loading = false;
        })
        .catch((error) => {
          if (error) {
            this.$router.push("/sales/orders");
          }
        });
    },
    closeAll() {
      this.nfe.add.modal = false;
      this.nfe.del.modal = false;
    },
    showStatus() {
      this.closeAll();
      this.status.modal = false;
      this.status.modal = true;
    },
    saveStatus() {
    },
    showAddNfe() {
      this.closeAll();
      this.nfe.add.modal = false;
      this.nfe.add.modal = true;
    },
    addNfe() {
    },
    showDelNfe(item) {
      this.closeAll();
      this.nfe.del.modal = true;
      this.nfe.del.id = item.id;
    },
    delNfe(id) {
      if (id) {
        this.nfe.del.loading = true;

        api
          .delete("sales/orders/" + this.$route.params.id + "/nfe/" + id)
          .then((res) => {
            if (res.data.status == "success") {
              this.nfe.del.modal = false;
              this.$toast.success(res.data.message);

              this.getOrder();
            } else {
              this.$toast.error(res.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.nfe.del.modal = false;
              this.nfe.del.loading = false;
            }
          })
          .finally(() => {
            this.nfe.del.loading = false;
          });
      }
    },
    clickNfe(item) {
      if (item.url) {
        window.open(item.url, '_blank');
      }
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Pedidos</li>
      <li class="breadcrumb-item"><router-link tag="a" to="/sales/orders">Lista de Pedidos</router-link></li>
    </ol>

    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Ver pedido</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!order" class="card">
      <div class="card-body text-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>
    <template v-else-if="order && order.id">
      <div id="dropdown" class="d-flex mb-4">
        <div class="totals">
          <h6 class="m-0">{{ order.id }}</h6>
          <h6 class="m-0 text-secondary">No. do pedido</h6>
        </div>
        <div class="totals">
          <h6 class="m-0" v-if="order.status == 'pending'">Pendente</h6>
          <h6 class="m-0" v-else-if="order.status == 'approved'">Aprovado</h6>
          <h6 class="m-0" v-else-if="order.status == 'in_process'">Em separação</h6>
          <h6 class="m-0" v-else-if="order.status == 'shipped'">Enviado</h6>
          <h6 class="m-0" v-else-if="order.status == 'delivered'">Entregue</h6>
          <h6 class="m-0" v-else-if="order.status == 'canceled'">Cancelado</h6>
          <h6 class="m-0 text-secondary">Status do pedido</h6>
        </div>
        <b-dropdown variant="outline-light">
          <template #button-content>
            Ações
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
            </svg>
          </template>
          <b-dropdown-item v-on:click="showAddTracking()" :disabled="order.status == 'pending' || order.tracking.status !== 'pending'">Adicionar código de rastreio</b-dropdown-item>
          <b-dropdown-item v-on:click="showAddNfe()" :disabled="order.status == 'pending' || order.nfe.status !== 'pending'">Adicionar nota fiscal</b-dropdown-item>
          <b-dropdown-item v-on:click="showStatus()" :disabled="order.status == 'pending'">Alterar status do pedido</b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
          <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
        </div>
      </div>

      <div class="order-info row">
        <div class="col-md-6">
          <fieldset class="card-h-fix">
            <legend>Informações do cliente</legend>
            <div class="client-info">
              <h6 class="m-0">{{ order.client.name }}</h6>
              <h6 class="m-0">{{ order.client.person_number }}</h6>
              <h6 class="m-0">{{ order.client.email }}</h6>
              <h6 class="m-0">{{ order.client.cellphone }}</h6>
              <a class="btn btn-outline-success py-1 px-2 mt-2" target="_blank" :href="order.client.whatsapp">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
                  </path>
                </svg>
                <span class="ml-1">Enviar WhatsApp</span>
              </a>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <fieldset class="card-h-fix">
            <legend>Informações do envio</legend>
            <div class="shipping-info">
              <h6 class="m-0">{{ order.shipping.details.address }}, {{ order.shipping.details.number }}</h6>
              <h6 class="m-0" v-if="order.shipping.details.complement">{{ order.shipping.details.complement }}</h6>
              <h6 class="m-0">{{ order.shipping.details.district }}</h6>
              <h6 class="m-0">{{ order.shipping.details.city }} - {{ order.shipping.details.state }}</h6>
              <h6 class="m-0">{{ order.shipping.details.zipcode }}</h6>
            </div>
          </fieldset>
        </div>
      </div>

      <fieldset>
        <legend>Produtos</legend>
        <div class="table-responsive">
          <b-table :items="order.products.items" :fields="fields.products" class="table-nowrap table-style table-hover" head-variant="light" responsive>
            <template #cell(image)="row">
              <img class="avatar-lg rounded" :src="row.item.image" />
            </template>
            <template #cell(name)="row">
              {{ row.item.name }}
            </template>
            <template #cell(price)="row">
              {{ row.item.price | currency }}
            </template>
            <template #cell(subtotal)="row">
              {{ row.item.subtotal | currency }}
            </template>
            <template #cell(id)="row">
              <b-dropdown variant="more">
                <template #button-content>
                  <i class="fas fa-ellipsis-h"></i>
                </template>
                <b-dropdown-item class="d-none" v-on:click="showEditItem(row.item)" :disabled="order.status !== 'open'">Editar</b-dropdown-item>
                <b-dropdown-item v-on:click="showDelItem(row.item)" class="text-danger" :disabled="order.status !== 'open'">Excluir</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </fieldset>
    </template>

    <b-modal v-model="status.modal" modal-class="modal-right" hide-header hide-footer>
      <div class="d-flex justify-content-between mb-5">
        <h4 class="m-0">Editar status do pedido</h4>
        <a class="btn btn-outline-link p-0" v-on:click="status.modal = false">Fechar</a>
      </div>
      <b-form @submit.prevent="saveStatus">
        <b-form-group label="Escolha um status" label-for="status.id">
          <select v-model="status.id" class="custom-select mb-2">
            <option value="approved" disabled>Aprovado</option>
            <option value="in_process">Em separação</option>
            <option value="shipped">Enviado</option>
            <option value="delivered">Entregue</option>
          </select>
        </b-form-group>
        <b-form-checkbox v-model="status.notification" switch size="lg"> Enviar notificação para o cliente</b-form-checkbox>
        <div class="mt-5">
          <button class="btn btn-default btn-lg btn-block text-uppercase" :disabled="this.status.loading">
            Salvar
            <b-spinner v-if="status.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </b-form>
    </b-modal>

    <b-modal v-if="order && order.id" v-model="nfe.add.modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4 class="mb-5">Adicionar arquivo</h4>
        <div>
          <AquivosUpload :order="order.id"></AquivosUpload>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <a class="btn btn-outline-link" v-on:click="nfe.add.modal = false;">Fechar <sup>ESC</sup></a>
        </div>
      </div>
    </b-modal>

    <b-modal v-if="order && order.id" v-model="nfe.del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir arquivo</h4>
        <p>Deseja realmente excluir esse arquivo?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delNfe(nfe.del.id)">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="nfe.del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.order-info .client-info h6,
.order-info .shipping-info h6 {
  line-height: 20px;
}
</style>