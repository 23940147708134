<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState, mapActions } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import Password from "@/components/widgets/input-password";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
    Languages,
    Password,
  },
  data() {
    return {
      loading: {
        register: false,
        contract: false,
      },

      success: false,

      contract: {
        title: "",
        content: "",
        status: false,
      },

      user: {
        name: "",
        responsible: "",

        email: "",
        cellphone: "",

        company_cnpj: "",
        company_name: "",
        company_opening: "",
        company_zipcode: "",
        company_address: "",
        company_number: "",
        company_complement: "",
        company_district: "",
        company_city: "",
        company_state: "",

        password: "",
        term: false,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  validations: {
    user: {
      name: { required },
      responsible: { required },

      email: { required, email },
      cellphone: { required },

      company_cnpj: { required },
      company_name: { required },
      company_opening: { required },
      company_zipcode: { required },
      company_address: { required },
      company_number: { required },
      company_complement: {},
      company_district: { required },
      company_city: { required },
      company_state: { required },

      password: { required, minLength: minLength(6) },
      term: { required },
    },
  },
  methods: {
    ...mapActions("account", ["register", "token"]),
    searchCep() {
      if (this.user.company_zipcode.length == 9) {
        api.get("https://cep.m2n.com.br/" + this.user.company_zipcode.replace(/\D/g, "")).then((response) => {
          if (response.data.city == "") {
            this.$toast.error('O CEP informado é inválido.');
          } else {
            if (response.data.address) {
              this.user.company_address = response.data.address;
            }
            if (response.data.district) {
              this.user.company_district = response.data.district;
            }
            this.user.company_city = response.data.city;
            this.user.company_state = response.data.state;
          }
        });
      }
    },
    registerSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading.register = true;
        api
          .post("user", {
            name: this.user.name,
            responsible: this.user.responsible,

            email: this.user.email,
            cellphone: this.user.cellphone,

            company_cnpj: this.user.company_cnpj,
            company_name: this.user.company_name,
            company_opening: this.user.company_opening,
            company_zipcode: this.user.company_zipcode,
            company_address: this.user.company_address,
            company_number: this.user.company_number,
            company_complement: this.user.company_complement,
            company_district: this.user.company_district,
            company_city: this.user.company_city,
            company_state: this.user.company_state,

            password: this.user.password,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.user.name = "";
              this.user.responsible = "";

              this.user.email = "";
              this.user.cellphone = "";

              this.user.company_cnpj = "";
              this.user.company_opening = "";
              this.user.company_zipcode = "";
              this.user.company_address = "";
              this.user.company_number = "";
              this.user.company_complement = "";
              this.user.company_district = "";
              this.user.company_city = "";
              this.user.company_state = "";

              this.user.password = "";
              this.$v.$reset();

              this.$toast.success(response.data.message)

              this.success = true;

              const key = response.data.token;
              this.token({ key });
            } else {
              this.$toast.error(response.data.message)
            }

            this.loading.register = false;
          })
          .catch((error) => {
            if (error) {
              this.$toast.error('Ocorreu um erro ao tentar cadastrar, tente novamente.');
              this.loading.register = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img style="max-width: 150px" src="@/assets/images/logo.png" />
            </router-link>
          </div>
          <div class="pt-5">
            <div class="border rounded py-3 mb-4">
              <h5 class="m-0 font-size-14 font-weight-normal text-uppercase text-center">
                Área dos <strong>Parceiros</strong>
              </h5>
            </div>
            <div v-if="success" class="text-center pt-4 pb-4">
              <h5>
                Parabéns!<br />
                Seu cadastro foi realizado com sucesso.
              </h5>
            </div>
            <div v-else>
              <b-form @submit.prevent="registerSubmit">
                <div class="divisor mb-3">
                  <span>Dados da Empresa</span>
                </div>
                <b-form-group label="Razão Social" label-for="company_name">
                  <b-form-input v-model="user.company_name" type="text" :class="{ 'is-invalid': $v.user.company_name.$error }"></b-form-input>
                  <div v-if="!$v.user.company_name.required" class="invalid-feedback">
                    A razão social é obrigatória.
                  </div>
                </b-form-group>
                <b-form-group label="Nome Fantasia" label-for="name">
                  <b-form-input v-model="user.name" type="text" :class="{ 'is-invalid': $v.user.name.$error }"></b-form-input>
                  <div v-if="!$v.user.name.required" class="invalid-feedback">
                    O nome fantasia é obrigatório.
                  </div>
                </b-form-group>
                <b-form-group label="CNPJ" label-for="company_cnpj">
                  <b-form-input v-model="user.company_cnpj" type="text" inputmode="numeric" v-mask="'##.###.###/####-##'" :class="{ 'is-invalid': $v.user.company_cnpj.$error }"></b-form-input>
                  <div v-if="!$v.user.company_cnpj.required" class="invalid-feedback">
                    O CNPJ é obrigatório.
                  </div>
                </b-form-group>
                <b-form-group label="Data de Abertura" label-for="company_opening">
                  <b-form-input v-model="user.company_opening" type="text" inputmode="numeric" v-mask="'##/##/####'" :class="{ 'is-invalid': $v.user.company_opening.$error }"></b-form-input>
                  <div v-if="$v.user.company_opening.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_opening.required">
                      Data de Abertura é obrigatório.
                    </span>
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Contatos</span>
                </div>

                <b-form-group label="Nome do Responsável" label-for="responsible">
                  <b-form-input v-model="user.responsible" type="text" :class="{ 'is-invalid': $v.user.responsible.$error }"></b-form-input>
                  <div v-if="!$v.user.responsible.required" class="invalid-feedback">
                    O nome do responsável é obrigatório.
                  </div>
                </b-form-group>
                <b-form-group label="E-mail" label-for="email">
                  <b-form-input v-model="user.email" type="email" inputmode="email" :class="{ 'is-invalid': $v.user.email.$error }"></b-form-input>
                  <div v-if="$v.user.email.$error" class="invalid-feedback">
                    <span v-if="!$v.user.email.required">O e-mail é obrigatório.</span>
                    <span v-if="!$v.user.email.email">Informe um e-mail válido</span>
                  </div>
                </b-form-group>
                <b-form-group label="Celular" label-for="cellphone">
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input v-model="user.cellphone" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.cellphone.$error }" v-mask="'(##) #####-####'"></b-form-input>
                  </b-input-group>
                  <div v-if="!$v.user.cellphone.required" class="invalid-feedback">
                    O celular é obrigatório.
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Endereço</span>
                </div>

                <b-form-group label="CEP" label-for="company_zipcode">
                  <b-form-input v-on:blur="searchCep()" v-model="user.company_zipcode" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.company_zipcode.$error }" autocomplete="off"
                    v-mask="'#####-###'"></b-form-input>
                  <div v-if="$v.user.company_zipcode.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_zipcode.required">O CEP é obrigatório.</span>
                    <span v-if="!$v.user.company_zipcode.invalid">O CEP informado é invalido.</span>
                  </div>
                </b-form-group>
                <b-form-group label="Endereço" label-for="company_address">
                  <b-form-input v-model="user.company_address" type="text" :class="{ 'is-invalid': $v.user.company_address.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.company_address.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_address.required">O endereço é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group label="Número" label-for="company_number">
                  <b-form-input v-model="user.company_number" type="text" :class="{ 'is-invalid': $v.user.company_number.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.company_number.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_number.required">O número é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group label="Complemento" label-for="company_complement">
                  <b-form-input v-model="user.company_complement" type="text" :class="{ 'is-invalid': $v.user.company_complement.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.company_complement.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_complement.required">O complemento é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group label="Bairro" label-for="company_district">
                  <b-form-input v-model="user.company_district" type="text" :class="{ 'is-invalid': $v.user.company_district.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.company_district.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_district.required">O bairro é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group label="Cidade" label-for="company_city">
                  <b-form-input class="bg-soft-info" v-model="user.company_city" type="text" :class="{ 'is-invalid': $v.user.company_city.$error }" autocomplete="off"
                    readonly="readonly"></b-form-input>
                  <div v-if="$v.user.company_city.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_city.required">A cidade é obrigatória.</span>
                  </div>
                </b-form-group>
                <b-form-group label="Estado" label-for="company_state">
                  <b-form-input class="bg-soft-info" v-model="user.company_state" type="text" :class="{ 'is-invalid': $v.user.company_state.$error }" autocomplete="off" readonly="readonly"
                    v-mask="'XX'"></b-form-input>
                  <div v-if="$v.user.company_state.$error" class="invalid-feedback">
                    <span v-if="!$v.user.company_state.required">O estado é obrigatório.</span>
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Dados de Acesso</span>
                </div>
                <b-form-group label="Senha" label-for="password">
                  <Password v-model="user.password" :v="$v.user.password"></Password>
                </b-form-group>

                <div class="mt-4">
                  <b-button :disabled="this.$v.$invalid || loading.register == true" type="submit" variant="default" class="btn-lg btn-block">
                    Cadastrar
                    <b-spinner v-if="loading.register" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
          <div class="my-5 text-center">
            <p v-if="success" class="text-dark">
              <router-link tag="a" to="/login" class="btn btn-default">Clique aqui para fazer o login</router-link>
            </p>
            <p v-else class="text-dark">
              Já possui uma conta?
              <router-link tag="a" to="/login" class="font-weight-medium text-muted">Login</router-link>
            </p>
            <Languages class="d-none" type="dropup"></Languages>
            <p class="d-none mt-5 font-size-12 text-uppercase text-muted">
              <a class="text-muted font-size-11" target="_blank" href="https://m2n.com.br/?utm_source=multiversobrasil.com&utm_medium=link&utm_campaign=logo">
                TECNOLOGIA POR<br />
                <img alt="M2N" style="width: 50px" src="@/assets/images/logo-m2n.svg" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.label-description {
  margin: -10px 0 10px 0;
}

.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}

.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
</style>
