<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Meu PIX') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="account.user" class="card">
      <div class="card-body table-responsive">
        <b-form class="p-3" @submit.prevent="pixSubmit">
          <img src="@/assets/images/payments/logo-pix.png" class="mb-4" style="height:50px;">
          <b-form-group label="Tipo" label-for="type">
            <b-form-input type="text" value="CNPJ" disabled></b-form-input>
          </b-form-group>
          <b-form-group label="Chave" label-for="key">
            <b-form-input type="text" :value="account.user.company.cnpj" disabled></b-form-input>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </Layout>
</template>