<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import Calendar from 'primevue/calendar';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
    Calendar,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Pedidos", value: "-", icon: "bx-shopping-bag", },
        { type: "line", title: "Total dos Pedidos", value: "R$ -,--", icon: "bx-dollar-circle", },
        { type: "line", title: "Produtos Vendidos", value: "R$ -,--", icon: "bx-package", },
        { type: "line", title: "Ticket Médio", value: "R$ -,--", icon: "bx-cart", },
      ],

      search: "",

      total: 0,

      fields: [
        { key: "id", label: "Pedido" },
        { key: "client", label: "Cliente" },
        { key: "products", label: "Produtos" },
        { key: "shipping", label: "Frete" },
        { key: "total", label: "Total" },
        { key: "tracking", label: "Rastreio", thClass: "text-center", tdClass: "text-center" },
        { key: "nfe", label: "NF-e", thClass: "text-center", tdClass: "text-center" },
        { key: "status", label: "Status" },
        { key: "actions", label: "" },
      ],
      items: null,

      status: "all",
      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
      period: [
        new Date(new Date().setDate(new Date().getDate() - 30)),
        new Date(),
      ],
    };
  },
  methods: {
    getOrders() {
      this.loading = true;
      this.errored = false;
      this.items = null;

      api
        .get("sales/orders", {
          search: this.search,
          period: this.period.length === 2 ? this.period.map(date => date.toISOString().split('T')[0]).join(',') : '',
          status: this.status,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.total = response.data.total;
            this.items = response.data.list;

            this.statData = [
              { type: "line", title: "Pedidos", value: this.total.orders.toString(), icon: "bx-shopping-bag", },
              { type: "line", title: "Total dos Pedidos", value: this.$options.filters.currency(this.total.sales).toString(), icon: "bx-dollar-circle", },
              { type: "line", title: "Produtos Vendidos", value: this.$options.filters.currency(this.total.products).toString(), icon: "bx-package", },
              { type: "line", title: "Ticket Médio", value: this.$options.filters.currency(this.total.average).toString(), icon: "bx-cart", },
            ];
          } else {
            this.total = 0;
            this.items = [];
          }
        })
        .catch((error) => {
          if (error) {
            this.total = 0;
            this.items = [];
          }
        })
    },
    clickOrder(item) {
      if (item.id) {
        this.$router.push("/sales/orders/" + item.id);
      }
    },
    filterSearch() {
      this.getOrders();
    }
  },
  mounted() {
    this.getOrders();
  },
  watch: {
    period(dates) {
      this.period = dates;

      if (dates[0] && dates[1]) {
        this.getOrders();
      }
    },
    status() {
      this.getOrders();
    },
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Pedidos</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Lista de Pedidos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <b-form @submit.prevent="filterSearch">
      <b-input-group class="search mb-3">
        <b-form-input v-model="search" placeholder="Pedidos, clientes ou código de rastreio" autocomplete="off"></b-form-input>
        <b-input-group-append>
          <b-button type="submit" variant="default">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
              <path
                d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z">
              </path>
            </svg>
            Buscar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light" class="period" ref="period">
        <template #button-content>
          período
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <Calendar v-model="period" inline selectionMode="range" dateFormat="dd/mm/yy" :manualInput="false" hide-header></Calendar>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          status
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="status" value="all">Todos</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="pending">Pendente</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="approved">Aprovado</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="in_process">Em separação</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="shipped">Enviado</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="delivered">Entregue</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="status" value="canceled">Cancelado</b-form-radio></b-dropdown-form>
      </b-dropdown>
      <b-dropdown variant="outline-light">
        <template #button-content>
          por página
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-form><b-form-radio v-model="perPage" value="10">10 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="25">25 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="50">50 itens</b-form-radio></b-dropdown-form>
        <b-dropdown-form><b-form-radio v-model="perPage" value="100">100 itens</b-form-radio></b-dropdown-form>
      </b-dropdown>
    </div>

    <div v-if="!items" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div class="card" v-else-if="items && items.length == 0">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum pedido encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover table-pointer min-vh-25" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" @row-clicked="clickOrder" responsive>
            <template #cell(client)="row">
              {{ row.item.client.name }}
            </template>
            <template #cell(total)="row">
              {{ row.item.total | currency }}
            </template>
            <template #cell(products)="row">
              <div class="d-flex" style="gap: 10px;">
                <template v-if="row.item.products.items[0] && row.item.products.items[0].image">
                  <img :src="row.item.products.items[0].image" class="avatar-sm rounded">
                </template>
                <template v-if="row.item.products.items[1] && row.item.products.items[1].image">
                  <img :src="row.item.products.items[1].image" class="avatar-sm rounded">
                </template>
                <div v-if="row.item.products.items.length >= 3" class="bg-soft-dark rounded d-flex align-items-center">
                  <div class="px-3 font-size-13">
                    +{{ row.item.products.items.length - 2 }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(shipping)="row">
              {{ row.item.shipping.value | currency }} – {{ row.item.shipping.time }} dias
            </template>
            <template #cell(status)="row">
              <span class="badge badge-status bg-soft-danger" v-if="row.item.status == 'pending'">Pendente</span>
              <span class="badge badge-status bg-soft-success" v-else-if="row.item.status == 'approved'">Aprovado</span>
              <span class="badge badge-status bg-soft-warning" v-else-if="row.item.status == 'in_process'">Em separação</span>
              <span class="badge badge-status bg-soft-info" v-else-if="row.item.status == 'shipped'">Enviado</span>
              <span class="badge badge-status bg-soft-primary" v-else-if="row.item.status == 'delivered'">Entregue</span>
              <span class="badge badge-status bg-soft-danger" v-else-if="row.item.status == 'canceled'">Cancelado</span>
            </template>
            <template #cell(tracking)="row">
              <template v-if="row.item.tracking.status == 'pending'">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(200, 200, 200, 1);">
                  <path
                    d="M19.15 8a2 2 0 0 0-1.72-1H15V5a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 1 1.73 3.49 3.49 0 0 0 7 .27h3.1a3.48 3.48 0 0 0 6.9 0 2 2 0 0 0 2-2v-3a1.07 1.07 0 0 0-.14-.52zM15 9h2.43l1.8 3H15zM6.5 19A1.5 1.5 0 1 1 8 17.5 1.5 1.5 0 0 1 6.5 19zm10 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z">
                  </path>
                </svg>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(12, 193, 0, 1);">
                  <path
                    d="M19.15 8a2 2 0 0 0-1.72-1H15V5a1 1 0 0 0-1-1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 1 1.73 3.49 3.49 0 0 0 7 .27h3.1a3.48 3.48 0 0 0 6.9 0 2 2 0 0 0 2-2v-3a1.07 1.07 0 0 0-.14-.52zM15 9h2.43l1.8 3H15zM6.5 19A1.5 1.5 0 1 1 8 17.5 1.5 1.5 0 0 1 6.5 19zm10 0a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z">
                  </path>
                </svg>
              </template>
            </template>
            <template #cell(nfe)="row">
              <template v-if="row.item.nfe.status == 'pending'">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(200, 200, 200, 1);">
                  <path
                    d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z">
                  </path>
                  <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
                </svg>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(12, 193, 0, 1);">
                  <path
                    d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z">
                  </path>
                  <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
                </svg>
              </template>
            </template>
            <template #cell(actions)="row">
              <router-link tag="a" :to="'/sales/orders/' + row.item.id">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                  <path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path>
                  <path
                    d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z">
                  </path>
                </svg>
              </router-link>
            </template>
          </b-table>
        </div>

        <b-pagination class="mt-3" v-model="currentPage" :total-rows="total.orders" :per-page="perPage" pills align="center"></b-pagination>
      </div>
    </div>
  </Layout>
</template>